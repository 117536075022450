<template>
  <mobile-screen :header="true" screen-class="resources-screen gray-bg">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("permissions", pageName, pageName) }}
        </div>
        <template v-slot:right>
          <button @click="initSubmit">
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <VeeForm
      ref="editMegaContainerForm"
      @submit="submitForm"
      :key="formKey"
      v-slot="{ errors }"
      class="form inline-input edit-form"
      :initial-values="selectedPermission && { ...selectedPermission }"
      novalidate
    >
      <ul class="clebex-item-section">
        <li
          class="clebex-item-section-item"
          :class="{ 'has-error': errors.name }"
        >
          <span class="error-message" v-if="errors.name">
            {{ errors.name }}
          </span>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">{{
                displayLabelName("permissions", pageName, "name")
              }}</label>
              <Field
                name="name"
                as="input"
                type="text"
                rules="required"
                :placeholder="displayLabelName('permissions', pageName, 'name')"
              />
            </div>
          </div>
        </li>
      </ul>
      <button type="submit" style="display: none;" ref="submitFormBtn">
        Submit form
      </button>
    </VeeForm>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import permissionsMixin from "@/services/mixins/permissions/permissions-mixin";
export default {
  name: "EditPermission",
  data() {
    return {
      formKey: 0
    };
  },
  computed: {
    ...mapState("permissions", ["selectedPermission"]),
    pageName() {
      const { containerType } = this;
      if (containerType === "USER") {
        return "edit-user-permission";
      } else if (containerType === "RESOURCE") {
        return "edit-resource-permission";
      } else if (containerType === "LEVEL") {
        return "edit-level-permission";
      }
      return "";
    }
  },
  watch: {
    selectedPermission: {
      immediate: true,
      handler() {
        if (this.formKey === 10) {
          this.formKey = 0;
        } else {
          this.formKey++;
        }
      }
    }
  },
  mounted() {
    if (!this.selectedPermission) {
      this.$router.push({ name: this.backLinkName });
    }
  },
  methods: {
    ...mapActions("permissions", ["setSelectedPermission"]),
    submitForm(values) {
      if (values) {
        this.submitCompanyData(values);
      }
    },
    submitCompanyData(values) {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .put(
          `${apiEndpoints.company.permissions}/${this.selectedPermission.id}`,
          values
        )
        .then(() => {
          this.fetchPermissions();
          this.setSelectedPermission(...this.selectedPermission, ...values);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    },
    initSubmit() {
      const { submitFormBtn } = this.$refs;
      submitFormBtn.click();
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  mixins: [permissionsMixin]
};
</script>
