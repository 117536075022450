import { mapActions, mapState } from "vuex";

export default {
  computed: {
    ...mapState("containers", ["containerAccessTypes"]),
    containerTypeId() {
      const { containerAccessTypes, containerType } = this;
      if (
        containerType &&
        containerAccessTypes &&
        containerAccessTypes.data &&
        containerAccessTypes.data.length
      ) {
        const filtered = containerAccessTypes.data.filter(
          item => item.type === containerType
        );
        if (filtered && filtered.length) {
          return filtered[0].id;
        }
        return null;
      }
      return null;
    },
    editRouteName() {
      const { containerType } = this;
      if (containerType === "USER") {
        return "r_edit-user-permission";
      } else if (containerType === "RESOURCE") {
        return "r_edit-resource-permission";
      } else if (containerType === "LEVEL") {
        return "r_edit-level-permission";
      }
      return "";
    }
  },
  methods: {
    ...mapActions("containers", ["getContainerAccessTypes"]),
    ...mapActions("permissions", ["getPermissions"]),
    fetchPermissions() {
      const { containerTypeId } = this;
      if (containerTypeId) {
        this.getPermissions({
          access_type_ids: [containerTypeId]
        });
      } else {
        this.getContainerAccessTypes();
      }
    }
  },
  props: {
    containerType: {
      required: true,
      type: String
    },
    backLinkName: {
      type: String,
      required: true
    }
  }
};
